<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="Roles" model-title="Roles"
          :model-api="['models','User','Roles']" model-key="roleId" :headers="headers" 
          :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
          searchable>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :rules="[rules.require()]" v-model="data.roleId" label="Role ID" :disabled="!isCreating"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="data.roleName" label="Role Name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-table-field v-model="data.permissions" title="Permissions" item-key="permissionId" :headers="permissionsHeaders" hide-toolbar show-create-pad auto-item-action>
                    <template v-slot:createPad="{data: tmplData,rules}">
                      <v-container fluid pa-0>
                        <v-row>
                          <v-col cols="12">
                            <v-master-field :rules="[rules.require()]" v-model="tmplData.permissionId"
                              :custom-api="['models','User','Permissions','query']" 
                              custom-api-text="description" custom-api-value="permissionId" 
                              label="Permissions" 
                              customApiEagerLoad show-code>
                            </v-master-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.description="props">
                      <v-label-api :api-url="['models','User','Permissions','find']" :api-key="props.item.permissionId" api-field="description" ></v-label-api>
                    </template>
                  </v-table-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Role ID',
        value: 'roleId',
        class: 'body-2'
      },
      {
        text: 'Role Name',
        value: 'roleName',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    permissionsHeaders: [
      {
        text: 'Permission ID',
        value: 'permissionId',
        class: 'body-2'
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
    ],
  }),
}
</script>

<style lang="css">
</style>
